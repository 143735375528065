import React from 'react';

const SummaryCard = () => (
    <div className="card">
        <div className="card-body">
            <h6 className="mb-3 fw-semibold text-uppercase">Summary</h6>
            <p>
                It will be as simple as occidental in fact, it will be Occidental. To an English person, it will seem like
                simplified English, as a skeptical Cambridge friend of mine told me what Occidental is.
            </p>
            <ul className="ps-4 vstack gap-2">
                <li>Product Design, Figma (Software), Prototype</li>
                <li>Four Dashboards : Ecommerce, Analytics, Project, etc.</li>
                <li>Create calendar, chat, and email app pages.</li>
                <li>Add authentication pages.</li>
                <li>Content listing.</li>
            </ul>
            <button type="button" className="btn btn-link link-success p-0">Read more</button>
            <div className="pt-3 border-top border-top-dashed mt-4">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <p className="mb-2 text-uppercase fw-medium">Create Date:</p>
                        <h5 className="fs-15 mb-0">15 Sep, 2021</h5>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <p className="mb-2 text-uppercase fw-medium">Due Date:</p>
                        <h5 className="fs-15 mb-0">29 Dec, 2021</h5>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <p className="mb-2 text-uppercase fw-medium">Priority:</p>
                        <span className="badge bg-danger fs-12">High</span>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <p className="mb-2 text-uppercase fw-medium">Status:</p>
                        <span className="badge bg-warning fs-12">In progress</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default SummaryCard;
