import React from 'react';

const CommentsCard = () => (
    <div className="card">
        <div className="card-header d-flex justify-content-between">
            <h4 className="card-title mb-0">Comments</h4>
            <button className="btn btn-soft-danger btn-sm">
                <i className="ri-share-line me-1"></i> Invite Member
            </button>
        </div>
        <div className="card-body">
            <div className="mb-2">Comment Section Placeholder</div>
            <form className="mt-4">
                <label htmlFor="comment" className="form-label">Leave a Comment</label>
                <textarea className="form-control bg-light border-light" id="comment" rows="3" placeholder="Enter your comment..."></textarea>
                <button type="button" className="btn btn-success mt-3">Post Comment</button>
            </form>
        </div>
    </div>
);

export default CommentsCard;
