import React, { useState, useEffect } from 'react';
import api from '../axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProfileUpdateForm from '../components/ProfileUpdateForm';
import ChangePasswordForm from '../components/ChangePasswordForm';
import ProfilePhotoUpload from '../components/ProfilePhotoUpload';

const ProfilePage = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/me');
                setUser(response.data);

            } catch (error) {
                console.error('Erreur lors de la récupération des informations utilisateur', error);
                navigate('/login');
            }
        };
        fetchUser();
    }, []);

    const handleLogout = async () => {
        try {
            await api.post('/logout');
            localStorage.removeItem('token');
            navigate('/login');
        } catch (error) {
            console.error('Erreur lors de la déconnexion', error);
        }
    };

    const handleUpdate = (updatedUser) => {
       
    };

    // Fonction pour mettre à jour la photo de profil
    const handlePhotoUpdate = (newPhotoUrl) => {
        setUser((prevUser) => ({ ...prevUser, photo: newPhotoUrl }));
    };


    if (!user) {
        return <Loading loading={true} />
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header user={user} handleLogout={handleLogout} />
                <Sidebar />
                <div className="vertical-overlay"></div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="position-relative mx-n4 mt-n4">
                                <div className="profile-wid-bg profile-setting-img">
                                    <img src="assets/images/profile-bg.jpg" className="profile-wid-img" alt="" />
                                    <div className="overlay-content"></div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xxl-3">
                                    <div className="card mt-n5">
                                        <div className="card-body p-4">
                                            <div className="text-center">
                                                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                    <img src={user ? user.image : "assets/images/users/avatar-1.jpg"}
                                                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                        alt="user-profile-image" />
                                                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                        <ProfilePhotoUpload user={user} onPhotoUpdate={handlePhotoUpdate} />
                                                        <label htmlFor="profile-img-file-input"
                                                            className="profile-photo-edit avatar-xs">
                                                            <span className="avatar-title rounded-circle bg-light text-body">
                                                                <i className="ri-camera-fill"></i>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <h5 className="fs-16 mb-1">{user ? `${user.nom} ${user.prenom}` : "User"}</h5>
                                                <p className="text-muted mb-0">
                                                    {user && user.role === "prospect" ? "Utilisateur" : ""}
                                                    {user && user.role === "client" ? "Utilisateur" : ""}
                                                    {user && user.role === "commercial" ? "Commercial(e)" : ""}
                                                    {user && user.role === "partenaire" ? "Partenaire" : ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-5">
                                                <div className="flex-grow-1">
                                                    <h5 className="card-title mb-0">Progression de votre dossier</h5>
                                                </div>
                                            </div>
                                            <div className="progress animated-progress custom-progress progress-label">
                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: "1%" }}
                                                    aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">
                                                    <div className="label">1%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="col-xxl-9">
                                    <div className="card mt-xxl-n5">
                                        <div className="card-header">
                                            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                                <li className="nav-item">
                                                    <button className="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                                        <i className="fas fa-home"></i> Informations peronnelles
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                                        <i className="far fa-user"></i> Changer le mot de passe
                                                    </button>
                                                </li>
                                                {/* <li className="nav-item">
                                                    <button className="nav-link" data-bs-toggle="tab" href="#experience" role="tab">
                                                        <i className="far fa-envelope"></i>Notifications
                                                    </button>
                                                </li>
                                                <li className="nav-item">
                                                    <button className="nav-link" data-bs-toggle="tab" href="#privacy" role="tab">
                                                        <i className="far fa-envelope"></i> Reclamations
                                                    </button>
                                                </li> */}
                                            </ul>
                                        </div>
                                        <div className="card-body p-4">
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="personalDetails" role="tabpanel">
                                                    <ProfileUpdateForm user={user} onUpdate={handleUpdate} />
                                                </div>

                                                <div className="tab-pane" id="changePassword" role="tabpanel">
                                                    <ChangePasswordForm user={user} handleLogout={handleLogout}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default ProfilePage;
