import React from 'react';

const ResourcesCard = () => (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title mb-4">Resources</h5>
            <div className="vstack gap-2">
                <div className="border rounded border-dashed p-2 d-flex justify-content-between align-items-center">
                    <span>App-pages.zip (2.2MB)</span>
                    <button type="button" className="btn btn-icon text-muted btn-sm"><i className="ri-download-2-line"></i></button>
                </div>
                <div className="border rounded border-dashed p-2 d-flex justify-content-between align-items-center">
                    <span>Velzon-admin.ppt (2.4MB)</span>
                    <button type="button" className="btn btn-icon text-muted btn-sm"><i className="ri-download-2-line"></i></button>
                </div>
            </div>
        </div>
    </div>
);

export default ResourcesCard;
