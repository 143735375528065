import React, { useState } from 'react';
import SummaryCard from './SummaryCard';
import CommentsCard from './CommentsCard';
import ResourcesCard from './ResourcesCard';

const DashboardCard = () => {
    const [activeTab, setActiveTab] = useState('overview');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card mt-n4 mx-n4">
                    <div className="bg-soft-warning">
                        <div className="card-body pb-0 px-4">
                            <div className="row mb-3">
                                <div className="col-md">
                                    <div className="row align-items-center g-3">
                                        <div className="col-md-auto">
                                            <div className="avatar-md">
                                                <div className="avatar-title bg-white rounded-circle">
                                                    <img src="assets/images/brands/slack.png" alt="Slack Logo" className="avatar-xs" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <h4 className="fw-bold">Velzon - Admin & Dashboard</h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div><i className="ri-building-line align-bottom me-1"></i> Themesbrand</div>
                                                <div className="vr"></div>
                                                <div>Create Date : <span className="fw-medium">15 Sep, 2021</span></div>
                                                <div className="vr"></div>
                                                <div>Due Date : <span className="fw-medium">29 Dec, 2021</span></div>
                                                <div className="vr"></div>
                                                <div className="badge rounded-pill bg-info fs-12">New</div>
                                                <div className="badge rounded-pill bg-danger fs-12">High</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-auto">
                                    <div className="hstack gap-1 flex-wrap">
                                        <button type="button" className="btn py-0 fs-16 favourite-btn active">
                                            <i className="ri-star-fill"></i>
                                        </button>
                                        <button type="button" className="btn py-0 fs-16 text-body">
                                            <i className="ri-share-line"></i>
                                        </button>
                                        <button type="button" className="btn py-0 fs-16 text-body">
                                            <i className="ri-flag-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Onglets */}
                            <ul className="nav nav-tabs-custom border-bottom-0">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'overview' ? 'active fw-semibold' : 'fw-semibold'}`}
                                        onClick={() => handleTabClick('overview')}
                                    >
                                        Overview
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'documents' ? 'active fw-semibold' : 'fw-semibold'}`}
                                        onClick={() => handleTabClick('documents')}
                                    >
                                        Documents
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'activities' ? 'active fw-semibold' : 'fw-semibold'}`}
                                        onClick={() => handleTabClick('activities')}
                                    >
                                        Activities
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 'team' ? 'active fw-semibold' : 'fw-semibold'}`}
                                        onClick={() => handleTabClick('team')}
                                    >
                                        Team
                                    </button>
                                </li>
                            </ul>

                            {/* Contenu des onglets */}
                            <div className="tab-content mt-4">
                                {activeTab === 'overview' && <div id="project-overview">
                                    <SummaryCard  />
                                    <CommentsCard  />
                                    <ResourcesCard  />
                                </div>}
                                {activeTab === 'documents' && <div id="project-documents">
                                   
                                </div>}
                                {activeTab === 'activities' && <div id="project-activities">Contenu de l'onglet Activities</div>}
                                {activeTab === 'team' && <div id="project-team">Contenu de l'onglet Team</div>}
                            </div>
                        </div>
                        {/* Fin du corps de la carte */}
                    </div>
                </div>
                {/* Fin de la carte */}
            </div>
            {/* Fin de la colonne */}
        </div>
    );
};

export default DashboardCard;
