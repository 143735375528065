import React, { useState } from 'react';
import api from '../axios';
import Swal from 'sweetalert2';  

const ChangePasswordForm = ({ user, handleLogout }) => {
    // État pour les champs du formulaire
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    
    // État pour gérer les erreurs de validation
    const [error, setError] = useState('');

    // Fonction pour gérer les changements de champ
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Fonction pour gérer la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Vérification que les nouveaux mots de passe correspondent
        if (formData.newPassword !== formData.confirmPassword) {
            setError('Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas.');
            return;
        }

        try {
            // Requête API pour changer le mot de passe
            const response = await api.put('/user/password/update', {
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword,
                newPassword_confirmation: formData.confirmPassword,
            });
            
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Mot de passe mise à jour !',
                    text: 'Votre mot de passe a été mise à jour avec succès.',
                });
                handleLogout()
            }
        } catch (error) {
            console.error("Error changing password", error);
            setError('Impossible de mettre à jour le mot de passe. Veuillez réessayer.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row g-2">
                <div className="col-lg-4">
                    <div>
                        <label htmlFor="oldpasswordInput" className="form-label">Ancien mot de passe</label>
                        <input
                            type="password"
                            className="form-control"
                            id="oldpasswordInput"
                            name="oldPassword"
                            placeholder="Taper votre ancien mot de passe"
                            value={formData.oldPassword}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div>
                        <label htmlFor="newpasswordInput" className="form-label">Nouveau mot de passe</label>
                        <input
                            type="password"
                            className="form-control"
                            id="newpasswordInput"
                            name="newPassword"
                            placeholder="Taper un nouveau mot de passe"
                            value={formData.newPassword}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div>
                        <label htmlFor="confirmpasswordInput" className="form-label">Confirmez le mot de passe </label>
                        <input
                            type="password"
                            className="form-control"
                            id="confirmpasswordInput"
                            name="confirmPassword"
                            placeholder="Confirmez le mot de passe"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            
            {/* Affichage de l'erreur de validation */}
            {error && <div className="text-danger mt-2">{error}</div>}
            
            <div className="text-end mt-4">
                <button type="submit" className="btn btn-primary">Changer le mot de passe</button>
            </div>
        </form>
    );
};

export default ChangePasswordForm;
