import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './../components/Loading';
import api from '../axios';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    // Vérifier si un utilisateur est déjà connecté
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const fetchUser = async () => {
                try {
                    const response = await api.get('/me');
                    setUser(response.data);
                    console.log(response.data);
                    if (user) {
                        window.location.href = '/dashboard';
                    }
                   
                } catch (error) {
                    console.error('Erreur lors de la récupération des informations utilisateur', error);
                   
                }
            };
            fetchUser();
            // Rediriger l'utilisateur vers le tableau de bord s'il est connecté
            //window.location.href = '/dashboard';
        }
    }, []);

    // Gérer la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Affiche le chargement
        try {
            const url = 'https://maboussoleapp.digital.ci/api/login';
            const url_dev = 'http://127.0.0.1:8000/api/login';
            const response = await axios.post(url, {
                email,
                password,
            });
            console.log('Connexion réussie :', response.data);
            localStorage.setItem('token', response.data.access_token);
            console.log('Token stocké dans localStorage :', response.data.access_token);
            window.location.href = '/dashboard';
        } catch (error) {
            console.error('Erreur de connexion', error);
            setError('Email ou mot de passe incorrect');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <Loading loading={loading} />
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card overflow-hidden">
                                <div className="row g-0">
                                    <div className="col-lg-6">
                                        <div className="p-lg-5 p-4 auth-one-bg h-100 d-flex flex-column">
                                            <div className="mb-4">
                                                <a href="/" className="d-block">
                                                    <img src="logo.png" alt="Logo" height="80" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="p-lg-5 p-4">
                                            <div>
                                                <h5 className="text-primary">NOUS SOMMES HEUREUX DE VOUS VOIR</h5>
                                                <p className="text-muted">Veuillez utiliser vos accès reçu par email ou autre pour vous connecter</p>
                                            </div>

                                            {error && <p className="text-danger">{error}</p>}

                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Identifiant</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        placeholder="Taper un email ou username"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    
                                                    <label className="form-label" htmlFor="password-input">Mot de passe</label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input
                                                            type={passwordVisible ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Taper un mot de passe"
                                                            id="password-input"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                                        >
                                                            <i className={passwordVisible ? "ri-eye-off-fill align-middle" : "ri-eye-fill align-middle"}></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="auth-remember-check"
                                                        checked={rememberMe}
                                                        onChange={() => setRememberMe(!rememberMe)}
                                                    />
                                                    <label className="form-check-label" htmlFor="auth-remember-check">Se souveir de moi</label>
                                                </div>

                                                <div className="mt-4">
                                                    <button className="btn btn-success w-100" type="submit">Connexion</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <p className="mb-0">&copy; {new Date().getFullYear()} © 2024 GESTION DES CLIENTS APP TOUT DROIT RESERVE  <i className="mdi mdi-heart text-danger"></i>  PAR DIGITAL CÔTE D'IVOIRE</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Login;
