import React, { useState, useEffect } from 'react';
import api from '../axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DashboardCard from '../components/DashboardCard';

const DossierPage = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/me');
                setUser(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des informations utilisateur', error);
               navigate('/login');
            }
        };
        fetchUser();
    }, []);

    const handleLogout = async () => {
        try {
            await api.post('/logout');
            localStorage.removeItem('token');
            navigate('/login');
        } catch (error) {
            console.error('Erreur lors de la déconnexion', error);
        }
    };

    if (!user) {
        return <Loading loading={true} />
    }

    return (
        <>
            <div id="layout-wrapper">
                <Header user={user} handleLogout={handleLogout} />
                <Sidebar />
                <div className="vertical-overlay"></div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <DashboardCard />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default DossierPage;
