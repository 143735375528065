import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className="app-menu navbar-menu">
            <div className="navbar-brand-box">
                <NavLink to="/dashboard" className="logo logo-dark">
                    <span className="logo-sm">
                       <img src="logo.png" alt="logo" height="50" />
                    </span>
                    <span className="logo-lg">
                    <img src="logo.png" alt="logo" height="50" />
                    </span>
                </NavLink>
                <NavLink to="/dashboard" className="logo logo-light">
                    <span className="logo-sm">
                       <img src="logo.png" alt="logo" height="50" />
                    </span>
                    <span className="logo-lg">
                    <img src="logo.png" alt="logo" height="50" />
                    </span>
                </NavLink>
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>

            <div id="scrollbar">
                <div className="container-fluid">
                    <ul className="navbar-nav" id="navbar-nav">
                        <li className="menu-title"><span>Menu</span></li>
                        
                        <li className="nav-item">
                            <NavLink to="/dashboard" className="nav-link menu-link" >
                                <i className="ri-dashboard-2-line"></i>
                                <span>Dashboards</span>
                            </NavLink>
                        </li>

                        {/* <li className="nav-item">
                            <NavLink to="/dossiers" className="nav-link menu-link" >
                                <i className="ri-briefcase-4-fill"></i>
                                <span>Mon dossier</span>
                            </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink to="/profile" className="nav-link menu-link" >
                                <i className="ri-settings-3-line"></i>
                                <span>Paramètres</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
