import axios from 'axios';

let url = "https://maboussoleapp.digital.ci/api"
let url_dev =  'http://127.0.0.1:8000/api'
// Configuration d'Axios avec l'URL de base de votre API Laravel
const api = axios.create({
    baseURL:url, // URL de base pour l'API
});

// Ajouter le token d'authentification à chaque requête si présent
api.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default api;
