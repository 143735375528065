import React, { useState, useEffect } from 'react';

const Header = ({ user, handleLogout }) => {
    // Initialiser l'état avec la valeur stockée dans localStorage ou "light" par défaut
    const [layoutMode, setLayoutMode] = useState(() => localStorage.getItem("layoutMode") || "light");

    // Fonction pour basculer entre le mode sombre et clair
    const toggleLayoutMode = () => {
        const newMode = layoutMode === "light" ? "dark" : "light";
        setLayoutMode(newMode);
        localStorage.setItem("layoutMode", newMode); // Stocker la nouvelle préférence dans localStorage
    };

    // Effet pour mettre à jour l'attribut sur la balise <html> à chaque changement de mode
    useEffect(() => {
        document.documentElement.setAttribute("data-layout-mode", layoutMode);
    }, [layoutMode]);


    const [sidebarSize, setSidebarSize] = useState("lg");

    // Fonction pour basculer entre les tailles de la barre latérale
    const toggleSidebarSize = () => {
        const newSize = sidebarSize === "lg" ? "sm" : "lg";
        setSidebarSize(newSize);
    };

    // Appliquer l'attribut data-sidebar-size sur <html> à chaque changement
    useEffect(() => {
        document.documentElement.setAttribute("data-sidebar-size", sidebarSize);
    }, [sidebarSize]);

    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box horizontal-logo">
                            <a href="/dashboard" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="logo.png" alt="logo" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="logo.png" alt="logo" height="22" />
                                </span>
                            </a>
                            <a href="/dashboard" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="logo.png" alt="logo" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="logo.png" alt="logo" height="22" />
                                </span>
                            </a>
                        </div>
                        <button
                            type="button"
                            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                            id="topnav-hamburger-icon"
                            onClick={toggleSidebarSize}
                        >
                            <span className="hamburger-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button>
                    </div>

                    <div className="d-flex align-items-center">


                        {/* Mode sombre */}
                        <div className="ms-1 header-item d-none d-sm-flex">
                            <button type="button"
                                onClick={toggleLayoutMode}
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                                <i className={`bx ${layoutMode === "light" ? "bx-moon" : "bx-sun"} fs-22`}></i>
                            </button>
                        </div>

                        {/* Notifications */}
                        <div className="dropdown topbar-head-dropdown ms-1 header-item">
                            <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown">
                                <i className='bx bx-bell fs-22'></i>
                                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">0</span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                                {/* Notifications Dropdown Content */}
                            </div>
                        </div>

                        {/* User Profile */}
                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button type="button" className="btn" data-bs-toggle="dropdown">
                                <span className="d-flex align-items-center">
                                    <img className="rounded-circle header-profile-user" src={user ? `${user.image}` : "assets/images/users/avatar-1.jpg"} alt="User Avatar" />
                                    <span className="text-start ms-xl-2">
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user ? `${user.nom} ${user.prenom}` : "User"}</span>
                                        <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                                            {user && user.role === "prospect" ? "Utilisateur" : ""}
                                            {user && user.role === "client" ? "Utilisateur" : ""}
                                            {user && user.role === "commercial" ? "Commercial(e)" : ""}
                                            {user && user.role === "partenaire" ? "Partenaire" : ""}
                                        </span>
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                {/* <a className="dropdown-item" href="/profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> Profile</a>
                                <div className="dropdown-divider"></div> */}
                                <button className="dropdown-item" onClick={handleLogout}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
