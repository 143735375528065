import React, { useState, useEffect } from 'react';
import api from '../axios';
import Swal from 'sweetalert2';

const ProfileUpdateForm = ({ user, onUpdate }) => {
    // État pour gérer chaque champ de profil avec les valeurs actuelles
    const [formData, setFormData] = useState({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        ville: '',
        pays: '',
        adresse: '',
        description: '',
    });

    // Charger les valeurs actuelles de l'utilisateur au montage du composant
    useEffect(() => {
        if (user) {
            setFormData({
                nom: user.nom || '',
                prenom: user.prenom || '',
                email: user.email || '',
                telephone: user.telephone || '',
                ville: user.ville || '',
                pays: user.pays || '',
                adresse: user.adresse || '',
                description: user.description || '',
            });
        }
    }, [user]);

    // Fonction pour gérer les changements de chaque champ
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Fonction pour soumettre le formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.put('/user/information/update', formData); // Adaptez l'URL selon votre backend
            if (response.status === 200) {
                onUpdate(response.data); // Mettez à jour les infos de l'utilisateur parent
                Swal.fire({
                    icon: 'success',
                    title: 'Information du profil mise à jour !',
                    text: 'Votre profil a été mise à jour avec succès.',
                });
            }
        } catch (error) {
            console.error("Error updating profile", error);
            Swal.fire({
                icon: 'error',
                title: 'Échec de la mise à jour',
                text: 'La mise à jour du profil a échoué. Veuillez réessayer.',
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="firstnameInput" className="form-label">Prenom(s)</label>
                        <input
                            type="text"
                            className="form-control"
                            id="firstnameInput"
                            name="prenom"
                            placeholder="Taper votre  prenom"
                            value={formData.prenom}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="lastnameInput" className="form-label">Nom</label>
                        <input
                            type="text"
                            className="form-control"
                            id="lastnameInput"
                            name="nom"
                            placeholder="Taper votre nom"
                            value={formData.nom}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="emailInput" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="emailInput"
                            name="email"
                            readOnly
                            value={formData.email}

                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="phoneInput" className="form-label">Numéro de téléphone</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phoneInput"
                            name="telephone"
                            placeholder="Taper votre numéro de téléphone"
                            value={formData.telephone}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="cityInput" className="form-label">Ville</label>
                        <input
                            type="text"
                            className="form-control"
                            id="cityInput"
                            name="ville"
                            placeholder="Taper votre ville"
                            value={formData.ville}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="countryInput" className="form-label">Pays</label>
                        <input
                            type="text"
                            className="form-control"
                            id="countryInput"
                            name="pays"
                            placeholder="Taper votre pays"
                            value={formData.pays}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="mb-3">
                        <label htmlFor="addressInput" className="form-label">Adresse</label>
                        <input
                            type="text"
                            className="form-control"
                            id="addressInput"
                            name="adresse"
                            placeholder="Taper votre adresse"
                            value={formData.adresse}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="mb-3">
                        <label htmlFor="descriptionInput" className="form-label">Description </label>
                        <textarea
                            className="form-control"
                            id="descriptionInput"
                            name="description"
                            placeholder="Taper votre description"
                            rows="3"
                            value={formData.description}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="hstack gap-2 justify-content-end">
                <button type="submit" className="btn btn-primary">Update</button>
                <button
                    type="button"
                    className="btn btn-soft-success"
                    onClick={() => setFormData({
                        nom: user.nom || '',
                        prenom: user.prenom || '',
                        email: user.email || '',
                        telephone: user.telephone || '',
                        ville: user.ville || '',
                        pays: user.pays || '',
                        adresse: user.adresse || '',
                        description: user.description || '',
                    })}
                >
                    Cancel
                </button>
            </div>
        </form>
    );
};

export default ProfileUpdateForm;
